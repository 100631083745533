// import monthlyModel from './monthlyModel';

/**
 * @param {Date} startDate
 * @param {number} dueDay
 * @param {number} count
 * @param {number} cost
 * @param {number} minAmount
 * @param {boolean} joinMinAmount
 * @returns monthlyModel[]
 */
 function generate(startDate, dueDay, count, cost, minAmount, joinMinAmount) {
    /** @type {monthlyModel[]} */
    let monthlies = [];
    let _prevStartDate = new Date(startDate);
    let _skipMinAmount = 0;
    let tomorrowsDate = new Date();
    tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);
    tomorrowsDate = new Date(tomorrowsDate.toDateString());

    if (cost <= 0) {
        return [];
    }

    for (let i = 1; i - _skipMinAmount <= count; i++) {
        let _amount = cost;
        let _fromDate = new Date(_prevStartDate);
        let _dueDate = new Date(_prevStartDate);

        _dueDate.setUTCDate(_dueDate.getUTCDate() - 1);
        _dueDate.setUTCMonth(_dueDate.getUTCMonth() + 1);

        // se for mensalidade parcial, diminuir dias até bater a data de vencimento
        if (_dueDate.getUTCDate() != dueDay) {
            while (_dueDate.getUTCDate() != dueDay) {
                _dueDate.setUTCDate(_dueDate.getUTCDate() - 1);
            }
            let seconds = Math.floor((_dueDate - _prevStartDate) / 1000);
            if (seconds < 0) {
                seconds *= -1;
            }
            const daysBetween = Math.floor(seconds / 86400) + 1;

            _amount = Math.floor((cost / 30) * daysBetween);
        }

        // data de vencimento do boleto
        let _boletoDueDate = _dueDate
        if (_boletoDueDate < tomorrowsDate) {
            _boletoDueDate = tomorrowsDate;
        }

        // objeto de saída
        /** @type {monthlyModel} */
        const element = {
            _minAmountAlert: (_amount < minAmount),
            from: _fromDate,
            to: _dueDate,
            boletoDueDate: _boletoDueDate,
            amount: _amount,
        };

        monthlies.push(element);
        _prevStartDate = new Date(_dueDate);
        _prevStartDate.setUTCDate(_prevStartDate.getUTCDate() + 1);

        if (joinMinAmount && element._minAmountAlert) {
            _skipMinAmount++;
        }
    }

    // une as 2 primeiras mensalidades caso a primeira esteja abaixo do valor minimo
    if (joinMinAmount && monthlies.length >= 2 && monthlies[0]._minAmountAlert) {
        monthlies[1].from = monthlies[0].from
        monthlies[1].amount += monthlies[0].amount;
        monthlies.shift();
    }

    return monthlies;
}

export default generate