<template>
  <div class="mensalidades-isp-add my-1">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="from">Cobrar desde</label>
          <input v-model="form.from" type="date" class="form-control" name="from" id="from" aria-describedby="helpId" placeholder="entre com a data">
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="day">Quantidade</label>
          <select v-model="form.qty" class="form-select" name="" id="">
            <option v-for="index in 24" :key="index" :value="index">{{index}}</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="day">Dia do vencimento</label>
          <select v-model="form.day" class="form-control" name="" id="">
            <option v-for="index in 28" :key="index" :value="index">{{index}}</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <button @click="handleSimulate()" :disabled="!canSimulate()" type="button" class="btn btn-success mt-3 w-100">
          <font-awesome-icon :icon="['fas', 'eye']" fixed-width class="me-1"/>
          Pré-visualizar
        </button>
        <!-- <button @click="handleGenAndCreate()" :disabled="!canSimulate()" type="button" class="btn btn-success mt-3 w-100">
          <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>
          Adicionar
        </button> -->
      </div>
    </div>

    <!-- modal-view-simulate -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-view-simulate" id="btn-modal-view-simulate-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-view-simulate" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'calculator']" fixed-width/>
              Simulação
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <table class="table table-sm-pc table-striped table-hover">
              <thead>
                <tr>
                  <th width="32">#</th>
                  <th>De</th>
                  <th>Até</th>
                  <th>Vencimento</th>
                  <th class="text-center">Valor</th>
                  <th width="70"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="m in monthlies" :key="m.index">
                  <td scope="row">#{{m.index+1}}</td>
                  <td v-if="(!m._edit)">{{date().toBrazilDate(m.from)}}</td>
                  <td v-if="(!m._edit)">{{date().toBrazilDate(m.to)}}</td>
                  <td v-if="(!m._edit)">{{date().toBrazilDate(m.boletoDueDate)}}</td>
                  <td v-if="(!m._edit)" class="text-center" width="80">
                    <span v-if="(!m._edit)">
                      {{shared.moeda.toStringBrl(m.amount)}}
                    </span>
                  </td>
                  <td v-if="(!m._edit)" class="text-end">
                    <button @click="simulacao.startEdit(m)" type="button" class="btn btn-primary btn-sm me-1">
                      <font-awesome-icon :icon="['fas', 'edit']" fixed-width/>
                    </button>
                  </td>

                  <!-- edit -->
                  <td v-if="(m._edit)" colspan="5">
                    <div class="row g-1">
                      <div class="col-6 col-sm">
                        <label class="required fw-bold">Vencimento</label>
                        <input v-model="m._boletoDueDateEdit" type="date" class="form-control form-control-sm text-center">
                      </div>
                      <div class="col-6 col-sm">
                        <label class="required fw-bold">Valor</label>
                        <CurrencyInput v-model="m._amountEdit" :options="shared.moeda.currencyInputOptions()" placeholder="0,00" class="form-control form-control-sm text-end"/>
                      </div>
                      <div class="col-12 col-sm-auto pt-sm-4 text-end">
                        <button @click="simulacao.cancelEdit(m)" type="button" class="btn btn-outline-secondary btn-sm me-1">
                          <font-awesome-icon :icon="['fas', 'times']" fixed-width/> Cancelar
                        </button>
                        <button @click="simulacao.editApply(m)" type="button" class="btn btn-primary btn-sm">
                          <font-awesome-icon :icon="['fas', 'check']" fixed-width/> Salvar
                        </button>
                      </div>
                    </div>
                  </td>
                  <!-- edit -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-view-simulate-fecha">Fechar</button>
            <button type="button" @click="handleCreate()" class="btn btn-success">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-view-simulate -->
  </div>
</template>

<script>
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'
import date from '@/modules/date'
// import clone from '@/modules/clone'
import godate from '@/modules/godate'
import shared from '../modules/shared'
import CurrencyInput from '@/components/CurrencyInput.vue'
import mensalidade from '@/modules/mensalidade';

export default {
  name: 'MensalidadesISPAdd',
  // props: ["value"], //TODO: ADD PROP PARA IDENTIFICAR QUANDO SALVOU, OU ACIONAR TROCA DE ITEM ATIVO
  // prop: amount
  // prop: lasBilledDate
  // mixins: [godate],
  components: {
    CurrencyInput,
  },
  data() {
    return {
      subscriptionID: '',
      subscription: undefined,
      monthlies: [],
      form: {
        from: '',
        qty: 1,
        day: 0,
      },
      simulacao: {
        startEdit: this.simulacaoStartEdit,
        cancelEdit: this.simulacaoCancelEdit,
        editApply: this.simulacaoApplyEdit,
      }
    }
  },

  methods: {
    godate() {
      return godate;
    },
    date() {
      return date;
    },

    resetForm() {
      this.form.qty = 1;
      this.form.from = godate.toMysqlUTC(this.subscription.nextDueSuggestion);
      this.form.day = this.subscription.dueDay;
    },

    init() {
      loading(true);
      api.get(`/isp/subscriptions/${this.subscriptionID}?func_forMonthly`).then(res => {
        loading(false);
        this.subscription = res.data;
        this.resetForm();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    /**
     * @param {number} day
     * @param {Date} from
    */
    nextDue(day, from) {
      let d = new Date(from);

      // inc month
      if (d.getUTCDate()+1 === day) {
        d.setUTCMonth(d.getUTCMonth() + 1);
          return d;
      }

      // inc one day until
      for (;;) {
        d.setUTCDate(d.getUTCDate() + 1);
        if (d.getUTCDate() === day) {
          d.setUTCDate(d.getUTCDate() - 1);
          return d;
        }
      }
    },

    daysBetween(from, to) {
      /** @type {Date} */
      let f = new Date(from);
      /** @type {Date} */
      let t = new Date(to);
      let db = 0;

      if (t < f) {
        f = new Date(to);
        t = new Date(from);
      }

      for (;;) {
        if (f.getUTCDate() === t.getUTCDate() && f.getUTCMonth() === t.getUTCMonth() && f.getUTCFullYear() === t.getUTCFullYear()) {
          return db;
        }
        f.setUTCDate(f.getUTCDate() + 1);
        db++;
      }
    },

    generate() {
      const startDate = date.fromMysql(this.form.from);
      const dueDay = this.form.day;
      const count = this.form.qty;
      const cost = this.subscription.plan.price;
      const minAmount = 1000;
      const joinMinAmount = true;

      if (cost <= 0) {
        dialogo.alerta('Não pode gerar mensalidade para um plano de custo zero');
        return;
      }

      const monthlies = mensalidade.generate(startDate, dueDay, count, cost, minAmount, joinMinAmount);

      this.monthlies = [];
      monthlies.forEach((x, i) => {
        x.index = i;
        x._edit = false;
        this.monthlies.push(x);
      });
    },

    handleSimulate() {
      this.generate();
      if (this.monthlies.length > 0) {
        document.getElementById('btn-modal-view-simulate-abre').click();
      }
    },

    canSimulate() {
      switch (true) {
        case (!this.form.from):
        case (!this.form.day):
        case (!this.form.qty):
          return false;

        default:
          return true;
      }
    },

    async insert() {
      const sendData = this.monthlies;
      for (const x of sendData) {
        x.amount = this.moedaToInt(x.amount);
      }

      loading(true);
      await api.post(`/isp/subscriptions/${this.subscriptionID}/monthlies`, sendData).then(() => {
        loading(false);
        this.init();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    async handleGenAndCreate() {
      this.generate();
      await this.insert();
      this.goMonthly();
    },

    async handleCreate() {
      await this.insert();
      document.getElementById('btn-modal-view-simulate-fecha').click();
      this.goMonthly();
    },

    goMonthly() {
      document.getElementById('nav-monthlies-list-tab').click();
      document.getElementById('refresh-monthly-list').click();
    },

    //TODO: FUNÇÃO: VER COMO FICA / PRÉ-VISUALIZAR: gerar e mostrar em um modal antes, permitir editar e então subir do jeito que tá
    // permitir fundir 2 mensalidades sequenciais em 1, somando o valor e o período

    simulacaoStartEdit(m) {
      m._edit = true;
      m._amountEdit = m.amount;
      m._boletoDueDateEdit = date.toMysqlBrasil(m.boletoDueDate);
    },

    simulacaoCancelEdit(m) {
      m._edit = false;
    },

    simulacaoApplyEdit(m) {
      // TODO: VERIFICAR SE DATA DO BOLETO NÃO É MENOR QUE AMANHÃ, SE FOR, MENSAGEM DE ERRO
      m.amount = m._amountEdit;
      m.boletoDueDate = date.fromMysqlBrasil(m._boletoDueDateEdit);
      m._edit = false;
    },

    moedaToInt(valor) {
        valor = String(valor);

        if (!valor) {
            valor = '0,00';
        }

        var valorInt = +valor.replace(/[^0-9]/g, '');

        if (typeof valorInt === 'number') {
            return valorInt;
        }

        return 0;
    },
  },
  computed: {
    shared() {
      return shared;
    },
  },
  created() {
    this.form.qty = 12;
    this.form.day = undefined;

    this.subscriptionID = this.$route.params.assinaturaID;
    if (!this.subscriptionID) {
      this.$router.push({ path: `/assinaturas-isp` });
      return;
    }

    this.init();
  }
}


</script>
