<script>
import api from '@/modules/api'
import shared from '../modules/shared'

export default {
  name: "CustomerInfoHeader",
  props: {
    customerId: {
      type: String,
      default: '',
    },
    addressId: {
      type: String,
      default: '',
    },
    subscriptionId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      failed: false,
      customer: undefined,
      subscription: undefined,
    }
  },
  methods: {
    async getCustomer() {
      this.loading = true;

      await api.get(`/customers/${this.customerId}`).then(res => {
        this.customer = res.data;
      }).catch(() => {
        this.loading = false;
        this.failed = true;
      });

      if (this.subscriptionId) {
        await api.get(`/isp/subscriptions/${this.subscriptionId}`).then(res => {
          this.subscription = res.data;
        }).catch(() => {
          this.loading = false;
          this.failed = true;
        });
      }

      this.loading = false;
    },

    phoneHuman() {
      return shared.customer.phonesReadable(this.customer.phones);
    },

    matchAddress() {
      for (let i = 0; i < this.customer.addresses.length; i++) {
        const address = this.customer.addresses[i];

        if (!this.addressId) {
          return address;
        }

        if (this.addressId === address.id) {
          return address;
        }
      }

      return undefined;
    },

    addressHuman() {
      const address = this.matchAddress();

      if (!address) {
        return 'Cliente sem endereço';
      }

      return shared.customer.addressReadable(address);
    },
  },
  created() {
    this.getCustomer();
  },
};
</script>

<style scoped>
</style>

<template>
  <div class="flex mt-2 card-endereco border-primary">
    <div class="row mb-3">
      <!-- loading -->
      <div v-if="loading" class="text-center text-muted">
        <font-awesome-icon :icon="['fas', 'spinner']" spin />
      </div>
      <!-- loading -->

      <!-- load error -->
      <div v-if="(!loading && failed)" class="col-12 text-danger">
        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width/>
        falha ao carregar dados do cliente
      </div>
      <!-- load error -->

      <!-- customer data -->
      <div v-if="(!loading && !failed)" class="col-12">
        <div class="card">
          <div class="card-header fw-bold">Dados do cliente</div>
          <div class="card-body small">
            <p v-if="(subscription)" class="mb-0 text-info">
              <font-awesome-icon :icon="['fas', 'network-wired']" fixed-width/>
              Login: {{ subscription.login }} / {{ subscription.password }}
            </p>
            <p class="mb-0">
              <font-awesome-icon :icon="['fas', 'user']" fixed-width/>
              {{ customer.name }}
            </p>
            <p class="mb-0">
              <font-awesome-icon :icon="['fas', 'phone']" fixed-width/>
              {{ phoneHuman() }}
            </p>
            <p class="mb-0">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" fixed-width/>
              {{ addressHuman() }}
            </p>
          </div>
        </div>
      </div>
      <!-- customer data -->
    </div>
  </div>
</template>
