<template>
  <div class="mensalidades-isp">
    <h1 class="titulo-card fs-4">Detalhes da assinatura</h1>
    <div class="form-group card-principal">
      <div class="row justify-content-between">
        <div class="col-auto col-sm col-md-auto mb-3">
          <button @click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>

        <div class="col col-sm-auto col-md mb-3">
          <div class="row g-0 justify-content-end">
            <div class="col-auto col-lg"></div>
            <div class="col-12 col-md-3 col-lg-2 pb-1 ps-md-1">
              <button type="button" v-on:click="handleCancel(assinatura)" class="btn btn-danger w-100">
                  <font-awesome-icon :icon="['fas', 'ban']" fixed-width/>
                  Cancelar
                </button>
              </div>
              <div class="col-12 col-md-3 col-lg-2 pb-1 ps-md-1">
                <button type="button" v-on:click="handleEdit(assinatura)" class="btn btn-primary w-100">
                  <font-awesome-icon :icon="['fas', 'edit']" fixed-width/>
                  Editar
                </button>
              </div>
              <div v-if="(assinatura && assinatura.late)" class="col-12 col-md-3 col-lg-2 pb-1 ps-md-1">
                <button type="button" v-on:click="handleTrustUnlock(assinatura)" class="btn btn-warning w-100">
                  <font-awesome-icon :icon="['fas', 'unlock']" fixed-width/>
                  Desbloqueio
                </button>
              </div>
              <div class="col-12 col-md-3 col-lg-2 pb-1 ps-md-1">
                <a v-bind:href="printCarnetUrl" :class="{'disabled': !hasChecked}" target="_blank" class="btn btn-outline-secondary w-100">
                  <font-awesome-icon :icon="['fas', 'print']" fixed-width/>
                  Imprimir carnê
                </a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="(assinatura && assinatura.trustUnlock)" class="input-group mb-0 ps-1 text-info">
        <font-awesome-icon :icon="['fas', 'unlock']" fixed-width class="me-1"/>
        Assinatura com desbloqueio de confiança
      </div>

      <CustomerInfoHeader v-if="(cliente)" :customerId="cliente.id" :addressId="assinatura.addressId" :subscriptionId="assinatura.id"/>

      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-link active" id="nav-monthlies-list-tab" data-bs-toggle="tab" href="#nav-monthlies-list" role="tab" aria-controls="nav-monthlies-list" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'list']" fixed-width class="me-1"/>
            Mensalidades
          </a>
          <a class="nav-link" id="nav-mensalidades-add-tab" data-bs-toggle="tab" href="#nav-mensalidades-add" role="tab" aria-controls="nav-mensalidades-add" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>
            Adicionar
          </a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <!-- lista-mensalidades -->
        <div class="tab-pane fade show active" id="nav-monthlies-list" role="tabpanel" aria-labelledby="nav-monthlies-list-tab">
          <div v-if="assinatura && cliente && endereco && plano" class="alert alert-success">
            <p class="mb-0"><b>Assinatura:</b> #{{assinatura.id}}</p>
            <p class="mb-0"><b>Contratado:</b> {{assinatura.data_contratado}}</p>
            <p class="mb-0"><b>Plano:</b> {{plano.descricao}} / <b>R$</b> {{formataMoeda(assinatura.valor)}}</p>
            <p class="mb-0"><b>Cliente:</b> {{cliente.razao_social}}</p>
            <p class="mb-0"><b>Local instalação:</b> {{shared.customer.addressReadable(endereco)}}</p>
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <button @click="handlePesquisa()" class="btn btn-sm btn-outline-primary d-none" type="button" id="refresh-monthly-list">
                <font-awesome-icon :icon="['fas', 'sync']" fixed-width class="me-1"/>
              </button>
            </div>
          </div>
          <div class="d-flex">
            <!-- mobile -->
            <table class="table table-sm-pc table-striped table-hover d-sm-none">
              <thead>
                <tr>
                  <th width="15">
                    <label @click="handleCheckAll()" class="custom-control custom-checkbox">
                      <input type="checkbox" v-model="checkAll" class="custom-control-input">
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description"></span>
                    </label>
                  </th>
                  <th>De</th>
                  <th>Até</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="mensalidade in mensalidades" :key="mensalidade.id" @click="viewMonthlyHandle(mensalidade.id)" :class="cssMensalidade(mensalidade)" class="cursor-pointer">
                  <td scope="row" @click.stop>
                    <label v-if="monthlyCanBeChecked(mensalidade)" class="custom-control custom-checkbox">
                      <input type="checkbox" v-model="mensalidade._checked" class="custom-control-input">
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description"></span>
                    </label>
                  </td>
                  <td>
                    {{godate().toBrazilDate(mensalidade.from)}}
                  </td>
                  <td>
                    {{godate().toBrazilDate(mensalidade.to)}}
                  </td>
                  <td class="text-center">
                    <font-awesome-icon v-if="(mensalidade.status === 'smAberto')" :icon="['fas', 'clock']" fixed-width/>
                    <font-awesome-icon v-if="(mensalidade.status === 'smRecebido-x')" :icon="['fas', 'times-circle']" fixed-width/>
                    <font-awesome-icon v-if="(mensalidade.status === 'smRecebido')" :icon="['fas', 'check-circle']" fixed-width/>
                    {{traduzStatusMensalidade(mensalidade.status)}}
                  </td>
                  <td class="text-center">
                    R$ {{formataMoeda(mensalidade.amount)}}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- mobile -->

            <!-- desktop -->
            <table class="table table-sm-pc table-striped table-hover d-none d-sm-table">
              <thead>
                <tr>
                  <th width="15">
                    <label @click="handleCheckAll()" class="custom-control custom-checkbox">
                      <input type="checkbox" v-model="checkAll" class="custom-control-input">
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description"></span>
                    </label>
                  </th>
                  <th width="25">De</th>
                  <th>Até</th>
                  <th>Vencimento</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Valor</th>
                  <th width="10"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="mensalidade in mensalidades" :key="mensalidade.id" @click="viewMonthlyHandle(mensalidade.id)" :class="cssMensalidade(mensalidade)" class="cursor-pointer">
                  <td scope="row" @click.stop>
                    <label v-if="monthlyCanBeChecked(mensalidade)" class="custom-control custom-checkbox">
                      <input type="checkbox" v-model="mensalidade._checked" class="custom-control-input">
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description"></span>
                    </label>
                  </td>
                  <td>{{godate().toBrazilDate(mensalidade.from)}}</td>
                  <td>{{godate().toBrazilDate(mensalidade.to)}}</td>
                  <td>{{godate().toBrazilDate(mensalidade.to)}}</td>
                  <td class="text-center">
                    <font-awesome-icon v-if="(mensalidade.status === 'pending')" :icon="['fas', 'clock']" fixed-width/>
                    <font-awesome-icon v-if="(mensalidade.status === 'smRecebido-x')" :icon="['fas', 'times-circle']" fixed-width/>
                    <font-awesome-icon v-if="(mensalidade.status === 'paid')" :icon="['fas', 'check-circle']" fixed-width/>
                    {{traduzStatusMensalidade(mensalidade.status)}}
                  </td>
                  <td class="text-center">R$ {{formataMoeda(mensalidade.amount)}}</td>
                  <td @click.stop class="text-end">
                    <a v-if="(boletoForMonthly(mensalidade) && boletoForMonthly(mensalidade).bankBoletoUrl)" v-bind:href="boletoForMonthly(mensalidade).bankBoletoUrl" target="_blank" type="button" class="btn btn-outline-secondary btn-sm me-1">
                      <font-awesome-icon :icon="['fas', 'file-invoice']" fixed-width/>
                    </a>
                    <!-- <button type="button" @click="modalCobrancasAbreHandle(mensalidade)" class="btn btn-outline-primary btn-sm me-1">
                      <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                    </button> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- desktop -->
          </div>
          <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
        </div>
        <!-- lista-mensalidades -->

        <!-- mensalidades-add -->
        <div class="tab-pane fade show" id="nav-mensalidades-add" role="tabpanel" aria-labelledby="nav-mensalidades-add-tab">
          <MensalidadesISPAdd v-if="(assinatura)"/>
        </div>
        <!-- mensalidades-add -->
      </div>



      <!-- modal-view-monthly -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-view-monthly" id="btn-modal-view-monthly-abre">
        Launch
      </button>
      <!-- Modal -->
      <div class="modal fade" id="modal-view-monthly" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
          <div v-if="(viewMonthly.monthly)" class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <font-awesome-icon :icon="['fas', 'calendar']" fixed-width/>
                Mensalidade
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <h5 :class="cssMensalidade(viewMonthly.monthly)" class="mb-0">
                {{traduzStatusMensalidade(viewMonthly.monthly.status)}}
              </h5>
              <p class="mb-0"><strong>De: </strong>{{godate().toBrazilDate(viewMonthly.monthly.from)}}</p>
              <p class="mb-0"><strong>Até: </strong>{{godate().toBrazilDate(viewMonthly.monthly.to)}}</p>
              <p class="mb-0"><strong>Vencimento: </strong>{{godate().toBrazilDate(viewMonthly.monthly.dueDate)}}</p>
              <p class="mb-0"><strong>Status: </strong>
                <span :class="cssMensalidade(viewMonthly.monthly)">
                  {{traduzStatusMensalidade(viewMonthly.monthly.status)}}
                </span>
              </p>
              <p class="mb-0"><strong>Ordem de venda: </strong>
                <span v-if="(uuid().isEmpty(viewMonthly.monthly.orderId))">
                  Não gerado -
                  <a href="#" @click.stop.prevent="newOrderModalOpenHandle(viewMonthly.monthly)">Gerar</a>
                </span>
                <span v-if="(!uuid().isEmpty(viewMonthly.monthly.orderId))">
                  <a href="#" @click.stop.prevent="viewOrderModalHandle(viewMonthly.monthly.id)">Ver</a>
                </span>
              </p>
              <p class="mb-0"><strong>Valor: </strong>R$ {{formataMoeda(viewMonthly.monthly.amount)}}</p>
            </div>
            <div class="modal-footer">
              <button @click="removeMonthlyHandle(viewMonthly.monthly)" type="button" class="btn btn-outline-danger">
                <font-awesome-icon :icon="['fas', 'trash']" fixed-width/>
                Remover
              </button>
              <button @click="editMonthlyHandle(viewMonthly.monthly)" type="button" class="btn btn-outline-primary">
                <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
                Editar
              </button>
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-view-monthly-fecha">Fechar</button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal-view-monthly -->





      <!-- modal-cobrancas-mensalidade-isp -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-cobrancas-mensalidade-isp" id="btn-modal-cobrancas-mensalidade-isp-abre">
        Launch
      </button>
      <!-- Modal -->
      <div class="modal fade" id="modal-cobrancas-mensalidade-isp" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin scroll" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <font-awesome-icon :icon="['fas', 'file']" fixed-width/>
                Ordem de venda
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if="viewOrder.order" class="modal-body">
              <div class="row">

                <div class="col-12 col-sm-7 mb-2">
                  <p class="mb-0"><b>ID:</b> {{viewOrder.order.id}}</p>
                  <p class="mb-0"><b>Valor:</b> R$ {{formataMoeda(viewOrder.order.amount)}}</p>
                  <p class="mb-0"><b>Pendente:</b> R$ {{formataMoeda(orderPendingAmount(viewOrder.order))}}</p>
                  <p class="mb-0"><b>Status:</b> {{traduzStatusOrder(viewOrder.order.status)}}</p>
                </div>


                <div class="col-12 col-sm-5">
                  <div class="d-grid gap-2">
                    <button @click="showNewBillModalHandle(viewOrder.order)" v-bind:disabled="(viewOrder.order.status !== 'pending')" type="button" class="btn btn-sm btn-outline-primary">
                      <font-awesome-icon :icon="['fas', 'file']" fixed-width/>
                      Criar boleto
                    </button>
                    <button @click="modalReceiptOrderAbreHandle(viewOrder.order)" v-bind:disabled="(viewOrder.order.status !== 'pending')" type="button" class="btn btn-sm btn-success">
                      <font-awesome-icon :icon="['fas', 'hand-holding-usd']" fixed-width/>
                      Receber em dinheiro
                    </button>
                  </div>
                </div>

                <div v-if="viewOrder.order.transactions.length > 0" class="col-12 mt-3">
                  <h5 class="mb-0 text-info mt-3 border-start border-info"><font-awesome-icon :icon="['fas', 'file']" fixed-width/>Recebimentos</h5>
                  <p class="mb-0 text-muted ps-1">
                    Abaixo você vê a relação dos recebimentos referentes à essa ordem de venda
                  </p>
                  <table class="table table-sm-pc table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Tipo</th>
                        <th class="text-center" width="80">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="transaction in viewOrder.order.transactions" :key="transaction.id" :class="cssTransaction(transaction)">
                        <td scope="row">{{transaction.method}}</td>
                        <td class="text-center">R$ {{formataMoeda(transaction.paid)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div v-if="viewOrder.order.boletos.length > 0" class="col-12 mt-3">
                  <h5 class="mb-0 text-info mt-3 border-start border-info"><font-awesome-icon :icon="['fas', 'file']" fixed-width/>Boletos</h5>
                  <p class="mb-0 text-muted ps-1">
                    Relação de boletos gerados para essa ordem
                  </p>
                  <table class="table table-sm-pc table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Boleto</th>
                        <th>Status</th>
                        <th class="text-center" width="80">Valor</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="boleto in viewOrder.order.boletos" :key="boleto.id" :class="cssBoleto(boleto)" style="vertical-align: middle;">
                        <td scope="row">{{boleto.nossoNumero}}</td>
                        <td>{{boleto.status}}</td>
                        <td class="text-center">R$ {{formataMoeda(boleto.amount)}}</td>
                        <td class="text-end">
                          <a v-if="(podeVerBoleto(boleto))" v-bind:href="boleto.bankBoletoUrl" target="_blank" type="button" class="btn btn-outline-secondary btn-sm me-1">
                            <font-awesome-icon :icon="['fas', 'file-invoice']" fixed-width/>
                          </a>
                          <button type="button" v-if="(boleto.status === 'waiting')" @click="viewModalReceiveBoletoHandle(boleto)" class="btn btn-success btn-sm me-1">
                            <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                          </button>
                          <button type="button" v-if="(boleto.status === 'waiting')" @click="modalCobrancasRemoverHandle(boleto)" class="btn btn-danger btn-sm">
                            <font-awesome-icon :icon="['fas', 'ban']" fixed-width/>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button"
                  id="btn-modal-cobrancas-mensalidade-isp-fecha"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal-cobrancas-mensalidade-isp -->



      <!-- modal-nova-cobranca-mensalidade -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-nova-cobranca-mensalidade" id="btn-modal-nova-cobranca-mensalidade-abre">
        Launch
      </button>
      <!-- Modal -->
      <div class="modal fade" id="modal-nova-cobranca-mensalidade" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                Nova cobrança
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if="newOrder.monthly" class="modal-body">
              <p class="mb-0"><b>Mensalidade:</b> #{{newOrder.monthly.id}}</p>
              <p class="mb-0"><b>Vencimento:</b> {{newOrder.monthly.dueDate}}</p>
              <p class="mb-0"><b>Valor:</b> R$ {{formataMoeda(newOrder.monthly.amount)}}</p>
              <hr>
              <div class="row mt-3">
                <div class="col-12 col-sm-4 col-lg-3">
                  <label class="required">Tipo de cobrança</label>
                  <select v-model="newOrder.data.method" class="form-select">
                    <option v-bind:value="undefined" disabled>Selecione</option>
                    <option value="boleto">Boleto</option>
                  </select>
                </div>
                <div class="col-12 col-sm-4 col-lg-3">
                  <label class="required">Vencimento</label>
                  <input type="date" v-model="newOrder.data.dueDate" class="form-control">
                </div>
                <div class="col-12 col-sm-4 col-lg-2">
                  <label>Total</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                    </span>
                    <input type="text" v-bind:value="formataMoeda(newOrder.monthly.amount)" disabled class="form-control text-end">
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                  v-bind:disabled="(!newOrderModalPodeCriarCobranca())"
                  @click="newOrderModalCreateHandle()"
                  type="button"
                  class="btn btn-success">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                Criar cobrança
              </button>
              <button type="button"
                  @click="newOrderModalFechaHandle()"
                  id="btn-modal-nova-cobranca-mensalidade-fecha"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal-nova-cobranca-mensalidade -->





      <!-- modal-new-boleto-order -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-new-boleto-order" id="btn-modal-new-boleto-order-abre">
        Launch
      </button>
      <!-- Modal -->
      <div class="modal fade" id="modal-new-boleto-order" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                Novo boleto
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if="newBoleto.order" class="modal-body">
              <p class="mb-0"><b>Mensalidade:</b> #{{newBoleto.order.id}}</p>
              <p class="mb-0"><b>Vencimento:</b> {{newBoleto.order.dueDate}}</p>
              <p class="mb-0"><b>Valor:</b> R$ {{formataMoeda(newBoleto.order.amount)}}</p>
              <p class="mb-0"><b>Pendente:</b> R$ {{formataMoeda(orderPendingAmount(newBoleto.order))}}</p>
              <hr>
              <div class="row mt-3">
                <div class="col-12 col-sm-4 col-lg-2">
                  <label class="required">Banco</label>
                  <select v-model="newBoleto.form.bankId" class="form-select">
                    <option v-bind:value="undefined" disabled>Selecione</option>
                    <option v-for="bank in banksForBoleto()" :key="bank.id" v-bind:value="bank.id">{{bank.description}}</option>
                  </select>
                </div>
                <div class="col-12 col-sm-4 col-lg-3">
                  <label class="required">Vencimento</label>
                  <input type="date" v-model="newBoleto.form.dueDate" class="form-control">
                </div>
                <div class="col-12 col-sm-4 col-lg-2">
                  <label class="required">Total</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                    </span>
                    <input type="text" v-model="newBoleto.form.amount" class="form-control text-end">
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                  v-bind:disabled="(!newBoletoCanCreate())"
                  @click="newBoletoCreateHandle()"
                  type="button"
                  class="btn btn-success">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                Criar cobrança
              </button>
              <button type="button"
                  @click="closeNewBillModalHandle()"
                  id="btn-modal-new-boleto-order-fecha"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal-new-boleto-order -->



      <!-- modal-recebe-mensalidade -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-recebe-mensalidade" id="btn-modal-recebe-mensalidade-abre">
        Launch
      </button>

      <!-- Modal -->
      <div class="modal fade" id="modal-recebe-mensalidade" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <font-awesome-icon :icon="['fas', 'hand-holding-usd']" fixed-width/>
                Receber ordem em dinheiro
                <!-- TODO: NÃO DEVE RECEBER MAIS QUE O RESTANTE CONTENDO BOLETOS EM ABERTO, OU SEJA. UMA ORDEM DE 50,00 POSSUI 30 EM BOLETO ABERTO, SÓ PODE RECEBER 20 -->
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if="modalReceiptOrder.order" class="modal-body">
              <p class="mb-0"><b>Mensalidade:</b> #{{modalReceiptOrder.order.id}}</p>
              <p class="mb-0"><b>Vencimento:</b> {{modalReceiptOrder.order.dueDate}}</p>
              <p class="mb-0"><b>Valor:</b> R$ {{formataMoeda(modalReceiptOrder.order.amount)}}</p>
              <hr>
              <div class="row mt-3">
                <div class="col-12 col-sm-12 col-md-3">
                  <label class="required">Data recebimento</label>
                  <input type="date" v-model="modalReceiptOrder.data.paymentDate" class="form-control">
                </div>
                <div class="col-12 col-sm-4 col-md-3">
                  <label>Juros</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                    </span>
                    <currency-input v-model="modalReceiptOrder.data.interest" :options="shared.moeda.currencyInputOptions()" placeholder="0,00" class="form-control text-end"/>
                  </div>
                </div>
                <div class="col-12 col-sm-4 col-md-3">
                  <label>Desconto</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                    </span>
                    <currency-input v-model="modalReceiptOrder.data.discount" :options="shared.moeda.currencyInputOptions()" placeholder="0,00" class="form-control text-end"/>
                  </div>
                </div>
                <div class="col-12 col-sm-4 col-md-3">
                  <label>Total</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                    </span>
                    <input type="text" v-bind:value="formataMoeda(modalReceiptOrderTotal)" disabled class="form-control text-end">
                  </div>
                </div>
                <div class="col-12 col-sm-4 col-md-3">
                  <label>Conta</label>
                  <select v-model="modalReceiptOrder.data.walletId" class="form-select">
                    <option v-bind:value="undefined" disabled>Selecione</option>
                    <option v-for="wallet in wallets" :key="wallet.id" :value="wallet.id">{{wallet.description}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                  v-bind:disabled="(!modalReceiptOrderPodeCriarCobranca())"
                  @click="modalReceiptOrderReceberHandle()"
                  type="button"
                  class="btn btn-success">
                <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
                Receber
              </button>
              <button type="button"
                  @click="modalCobrancasFechaHandle()"
                  id="btn-modal-recebe-mensalidade-fecha"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal-recebe-mensalidade -->


      <!-- modal-receipt-boleto -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-receipt-boleto" id="btn-modal-receipt-boleto-abre">
        Launch
      </button>

      <!-- Modal -->
      <div class="modal fade" id="modal-receipt-boleto" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                Receber boleto em dinheiro
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if="modalReceiptBoleto.boleto" class="modal-body">
              <p class="mb-0"><b>Boleto:</b> #{{modalReceiptBoleto.boleto.nossoNumero}}</p>
              <p class="mb-0"><b>Tipo:</b> Boleto</p>
              <p class="mb-0"><b>Status:</b> {{modalReceiptBoleto.boleto.status}}</p>
              <p class="mb-0"><b>Valor:</b> R$ {{formataMoeda(modalReceiptBoleto.boleto.amount)}}</p>
              <hr>
              <div class="row mt-3">
                <div class="col-12 col-sm-12 col-md-3">
                  <label class="required">Data recebimento</label>
                  <input type="date" v-model="modalReceiptBoleto.form.paymentDate" class="form-control">
                </div>
                <div class="col-12 col-sm-4 col-md-3">
                  <label>Juros</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                    </span>
                    <currency-input v-model="modalReceiptBoleto.form.interest" :options="shared.moeda.currencyInputOptions" placeholder="0,00" class="form-control text-end"/>
                  </div>
                </div>
                <div class="col-12 col-sm-4 col-md-3">
                  <label>Desconto</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                    </span>
                    <currency-input v-model="modalReceiptBoleto.form.discount" :options="shared.moeda.currencyInputOptions" placeholder="0,00" class="form-control text-end"/>
                  </div>
                </div>
                <div class="col-12 col-sm-4 col-md-3">
                  <label>Total</label>
                  <div class="input-group">
                    <span class="input-group-text">
                      <font-awesome-icon :icon="['fas', 'dollar-sign']" fixed-width/>
                    </span>
                    <input type="text" v-bind:value="formataMoeda(modalReceiptBoletoTotal)" disabled class="form-control text-end">
                  </div>
                </div>
                <div class="col-12 col-sm-4 col-md-3">
                  <label>Conta</label>
                  <select v-model="modalReceiptBoleto.form.walletId" class="form-select">
                    <option v-bind:value="undefined" disabled>Selecione</option>
                    <option v-for="wallet in wallets" :key="wallet.id" :value="wallet.id">{{wallet.description}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row g-0 justify-content-between w-100">
                <div class="col-auto">
                  <button @click="modalReceiptBoletoReceberHandle()" v-bind:disabled="!modalReceiptBoletoCanReceive()" type="button" class="btn btn-success">
                    <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
                    Receber
                  </button>
                </div>
                <div class="col-auto">
                  <button type="button"
                      @click="modalCobrancasFechaHandle()"
                      id="btn-modal-receipt-boleto-fecha"
                      class="btn btn-outline-secondary"
                      data-bs-dismiss="modal">
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- modal-receipt-boleto -->

      <!-- modal-trust-unlock -->
      <!-- Button trigger modal -->
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-trust-unlock" id="btn-modal-trust-unlock-abre">
        Launch
      </button>

      <!-- Modal -->
      <div class="modal fade" id="modal-trust-unlock" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <font-awesome-icon :icon="['fas', 'unlock']" fixed-width/>
                Desbloqueio de confiança
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12 mb-2">
                  <label>Status</label>
                  <div class="form-check">
                    <label class="form-check-label cursor-pointer">
                      <input v-model="modalTrustUnlock.form.active" type="checkbox" class="form-check-input cursor-pointer">
                      Ativo
                    </label>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <label>Até</label>
                  <input v-model="modalTrustUnlock.form.trustUnlockUntil" :disabled="(!modalTrustUnlock.form.active)" type="date" class="form-control">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-trust-unlock-fecha">Fechar</button>
              <button type="button"
                  v-on:click="modalTrustUnlockHandleSave()"
                  v-bind:disabled="!modalTrustUnlockCanSave()"
                  class="btn btn-success">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal-trust-unlock -->
    </div>
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import baseurl from '../modules/baseurl'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import shared from '../modules/shared'
import model from '@/model'
import date from '@/modules/date'
import godate from '@/modules/godate'
import uuid from '@/modules/uuid'
import MensalidadesISPAdd from '@/views/MensalidadesISPAdd'
import CurrencyInput from '@/components/CurrencyInput.vue'
import CustomerInfoHeader from '@/components/CustomerInfoHeader'

export default {
  name: 'MensalidadesISP',
  components: {
    Paginador,
    MensalidadesISPAdd,
    CurrencyInput,
    CustomerInfoHeader,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      checkAll: false,
      assinaturaID: undefined,
      assinatura: undefined,
      cliente: undefined,
      endereco: undefined,
      plano: undefined,
      mensalidades: [],
      banks: [],
      wallets: [],
      boletos: [],

      viewMonthly: {
        monthly: undefined,
      },
      editMonthly: {
        monthly: undefined,
      },
      newOrder: {
        monthly: undefined,
        data: {
          method: undefined,
          dueDate: undefined,
        },
      },
      viewOrder: {
        order: undefined,
      },
      newBoleto: {
        order: undefined,
        form: {
          method: undefined,
          dueDate: undefined,
          amount: '',
          bankId: undefined,
        },
      },
      modalReceiptOrder: {
        order: undefined,
        data: {
          walletId: undefined,
          paymentDate: undefined,
          interest: 0,
          discount: 0,
        },
      },
      modalReceiptBoleto: {
        boleto: undefined,
        form: {
          walletId: undefined,
          paymentDate: undefined,
          interest: 0,
          discount: 0,
        },
      },

      modal: {
        data: {
          id: undefined,
          quantidade: undefined,
          valor: 0,
          mes_referencia: '',
          vencimento: '',
        },
      },
      modalCobrancas: {
        mensalidade: undefined,
        cobrancas: [],
        paginador: clone(model.paginador),
      },
      modalTrustUnlock: {
        subscription: undefined,
        form: {
          active: false,
          trustUnlockUntil: undefined,
        },
      },
    }
  },

  methods: {
    godate() {
      return godate;
    },
    uuid() {
      return uuid;
    },

    carregaAssinatura() {
      loading(true);
      api.get(`/isp/subscriptions/${this.assinaturaID}?func_forMonthly`).then(res => {
        this.assinatura = res.data;
        loading(false);

        this.cliente = res.data.customer;
        this.plano = res.data.plan;
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
        this.$router.push({ path: `/assinaturas-isp` });
      });
    },

    handlePesquisa() {
      loading(true);

      api.get(`/isp/subscriptions/${this.assinaturaID}/monthlies?${this.paginador.requestParams}&orderBy[created_at]=desc`).then(res => {
        loading(false);
        res.data.items.forEach(x => x._checked = false);
        this.mensalidades = res.data.items;
        this.boletos = res.data.aggregators.boletos;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
        this.checkAll = false;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    loadBanks() {
      api.get(`/banks?paginate.cancel`).then(res => {
        this.banks = res.data.items;
      }).catch(error => {
        dialogo.erroHttp(error);
      });
    },

    loadWallets() {
      api.get(`/wallets?paginate.cancel&active=true`).then(res => {
        this.wallets = res.data.items;
      }).catch(error => {
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    banksForBoleto() {
      const banks = this.banks.filter(x => {
        switch (x.type) {
          case 'asaas':
          case 'gerencianet':
            return true;

          default:
            return false;
        }
      });

      return banks;
    },

    boletoForMonthly(monthly) {
      const boletoStatus = ["waiting", "received"];
      const boleto = this.boletos.find(x => (x.ispMonthlyId == monthly.id && boletoStatus.includes(x.status)));

      return boleto;
    },

    monthlyCanBeChecked(monthly) {
      const monthlyStatus = ["pending", "late", "received"];

      switch (true) {
        case !monthlyStatus.includes(monthly.status):
        case !this.boletoForMonthly(monthly):
          return false;

        default:
          return true;
      }
    },

    handleCheckAll() {
      this.mensalidades.forEach(x => {
        if (this.monthlyCanBeChecked(x)) {
          x._checked = !this.checkAll;
        }
      });
    },

    handleCancel(subscription) {
      dialogo.confirmacao('Confirma cancelar a assinatura?').then(() => {
        loading(true);
        api.delete(`/isp/subscriptions/${subscription.id}`).then(() => {
          loading(false);
          this.$router.go(-1);
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    handleEdit(subscription) {
      this.$router.push(`/customers/${subscription.customerId}/subscriptions/${subscription.id}/edit`);
    },

    /////////////
    // modal trust unlock
    handleTrustUnlock(subscription) {
      this.modalTrustUnlock.subscription = subscription;
      this.modalTrustUnlock.form.active = subscription.trustUnlock;
      this.modalTrustUnlock.form.trustUnlockUntil = undefined;
      if (subscription.trustUnlockUntil) {
        this.modalTrustUnlock.form.trustUnlockUntil = godate.toMysqlUTC(subscription.trustUnlockUntil);
      }

      document.getElementById('btn-modal-trust-unlock-abre').click();
    },
    modalTrustUnlockCanSave() {
      const now = new Date();
      const until = date.fromMysql(this.modalTrustUnlock.form.trustUnlockUntil);

      switch (true) {
        case (this.modalTrustUnlock.form.active && !this.modalTrustUnlock.form.trustUnlockUntil):
        case (date.datePart(now) > until):
          return false;
      }

      return true;
    },
    modalTrustUnlockHandleSave() {
      let active = this.modalTrustUnlock.form.active;
      let until = active ? date.fromMysql(this.modalTrustUnlock.form.trustUnlockUntil) : undefined;

      const id = this.modalTrustUnlock.subscription.id;
      const data = {
        trustUnlock: active,
        trustUnlockUntil: until,
      };

      loading(true);
      api.patch(`/isp/subscriptions/${id}`, data).then(res => {
        this.modalTrustUnlock.subscription.trustUnlock = res.data.trustUnlock;
        this.modalTrustUnlock.subscription.trustUnlockUntil = res.data.trustUnlockUntil;
        loading(false);
      document.getElementById('btn-modal-trust-unlock-fecha').click();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
    // modal trust unlock
    /////////////

    /////////////
    // view modal
    viewMonthlyHandle(monthlyID) {
      this.$router.push(`/assinaturas-isp/${this.assinaturaID}/mensalidades/${monthlyID}`);
      // this.viewMonthly.monthly = monthly;
      // this.openModalViewMonthly();
    },

    openModalViewMonthly() {
      document.getElementById('btn-modal-view-monthly-abre').click();
    },

    closeModalViewMonthly() {
      document.getElementById('btn-modal-view-monthly-fecha').click();
    },

    removeMonthlyHandle(monthly) {
      dialogo.confirmacao('Confirma remover a mensalidade selecionada?').then(() => {
        loading(true);
        api.delete(`/isp/subscriptions/${this.assinaturaID}/monthlies/${monthly.id}`).then(() => {
          loading(false);
          this.closeModalViewMonthly();
          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    orderPendingAmount(order) {
      let paid = order.paid - order.interest + order.discount;

      return order.amount - paid;
    },
    // view modal
    /////////////

    /////////////
    // edit modal
    editMonthlyHandle(monthly) {
      this.closeModalViewMonthly();
      this.editMonthly.monthly = clone(monthly);
      this.editMonthly.monthly.dueDate = godate.toMysqlUTC(monthly.dueDate);
      this.openModalEditMonthly();
    },

    openModalEditMonthly() {
      document.getElementById('btn-modal-edita-mensalidade-abre').click();
    },

    editMonthlyCanSave() {
      switch (true) {
        case (this.editMonthly.monthly.amount < 0):
          return false;

        default:
          return true;
      }
    },

    editMonthlySaveHandle() {
      dialogo.confirmacao('Confirma salvar alterações nessa mensalidade?').then(() => {
        this.editMonthlySave();
      });
    },

    editMonthlySave() {
      const sendData = clone(this.editMonthly.monthly);
      sendData.dueDate = godate.fromMysql(sendData.dueDate);

      loading(true);
      api.put(`/isp/subscriptions/${this.assinaturaID}/monthlies`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-mensalidade-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
    // edit modal
    /////////////


    //////////////
    // order modal
    viewOrderModalHandle(monthlyId) {
      const apiUrl = `/isp/subscriptions/${this.assinaturaID}/monthlies/${monthlyId}/order`;

      loading(true);
      api.get(apiUrl).then(res => {
        loading(false);
        this.closeModalViewMonthly();
        if (!res.data.boletos) {
          res.data.boletos = [];
        }
        if (!res.data.transactions) {
          res.data.transactions = [];
        }
        this.viewOrder.order = res.data;
        this.viewOrderModalShow();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    viewOrderModalShow() {
        document.getElementById('btn-modal-cobrancas-mensalidade-isp-abre').click();
    },

    viewOrderModalClose() {
        document.getElementById('btn-modal-cobrancas-mensalidade-isp-fecha').click();
    },

    // order modal
    //////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////

    async handleEditaMensalidade(mensalidade) {
      this.resetModalMensalidade();

      const indice = mensalidade.mes_referencia.lastIndexOf('-');
      const mesReferencia = mensalidade.mes_referencia.slice(0, indice);

      this.editMonthly.monthly = clone(mensalidade);
      this.editMonthly.monthly.quantidade = 1;
      this.editMonthly.monthly.mes_referencia = mesReferencia;

      this.openModalEditMonthly();
    },

    handleRemoveMensalidade(mensalidade) {
      dialogo.confirmacao(`Confirma remover o Grupo <b>${mensalidade.descricao}</b>?`).then(() => {
        loading(true);

        api.delete(`/assinaturas-isp/${this.assinatura.id}/mensalidades/${mensalidade.id}`).then(() => {
          loading(false);

          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    resetModalMensalidade() {
      const hoje = new Date();
      const vencimentoStr = hoje.getUTCFullYear() + '-' + this.padLeft(hoje.getUTCMonth() + 1, 2, '0') +'-'+ this.assinatura.dia_vencimento;
      const mesReferencia = new Date(hoje.setMonth(hoje.getMonth()-1));
      const mesReferenciaStr = mesReferencia.getUTCFullYear() + '-' + this.padLeft(mesReferencia.getUTCMonth() + 1, 2, '0');

      this.editMonthly.monthly.quantidade = 1;
      this.editMonthly.monthly.valor = this.assinatura.valor;
      this.editMonthly.monthly.mes_referencia = mesReferenciaStr;
      this.editMonthly.monthly.vencimento = vencimentoStr;
    },

    cssMensalidade(mensalidade) {
      switch (mensalidade.status) {
        case 'pending':
          return 'text-info';

        case 'late':
          return 'text-warning';

        case 'creating':
        case 'received':
        case 'canceled':
          return 'text-muted-2';

        default:
          return '';
      }
    },

    traduzStatusMensalidade(status) {
      switch (status) {
        case 'creating':
          return 'Em criação';

        case 'pending':
          return 'Pendente';

        case 'late':
          return 'Atrasado';

        case 'received':
          return 'Recebido';

        case 'canceled':
          return 'Cancelado';

        default:
          return '';
      }
    },

    traduzStatusOrder(status) {
      switch (status) {
        case 'pending':
          return 'Pendente';

        case 'canceled':
          return 'Cancelado';

        case 'paid':
          return 'Recebido';

        default:
          return '';
      }
    },

    cssCobranca(cobranca) {
      switch (cobranca.status) {
        case 'pending':
          return 'text-info';

        case 'late':
          return 'text-warning';

        case 'paid':
        case 'received':
        case 'canceled':
          return 'text-muted-2';

        default:
          return '';
      }
    },

    cssTransaction(transaction) {
      switch (true) {
        case transaction.status !== 'done':
          return 'text-danger';

        default:
          return 'text-muted';
      }
    },

    cssBoleto(boleto) {
      switch (true) {
        case boleto.status === 'canceled':
          return 'text-muted-2';

        case boleto.status === 'waiting':
          return 'text-info';

        case boleto.status === 'create_error':
          return ['text-danger', 'text-decoration-line-through'];

        case boleto.status === 'late':
          return 'text-warning';

        default:
          return 'text-muted';
      }
    },

    podeVerBoleto(boleto) {
      const viewStatus = ['waiting', 'received', 'canceled'];
      switch (true) {
        case (viewStatus.includes(boleto.status) && !!boleto.bankBoletoUrl):
          return true;

        default:
          return false;
      }
    },

    traduzStatusCobranca(status) {
      switch (status) {
        case 'scAberto':
          return 'Aberto';

        case 'scCancelado':
          return 'Cancelado';

        case 'scRecebido':
          return 'Recebido';

        default:
          return '';
      }
    },

    podeEditarMensalidade(mensalidade) {
      switch (mensalidade.status) {
        case 'smAberto':
          return true;

        default:
          return false;
      }
    },

    /////////////////////////////////////
    // modal cobranças
    modalCobrancasReset() {
      this.modalCobrancas.cobrancas = [];
    },

    modalCobrancasAbre() {
      document.getElementById('btn-modal-cobrancas-mensalidade-isp-abre').click();
    },

    modalCobrancasFecha() {
      document.getElementById('btn-modal-cobrancas-mensalidade-isp-fecha').click();
    },

    modalCobrancasLista() {
      const assinaturaID = +this.$route.params.assinaturaID;

      const apiUrl = `` +
          `/assinaturas-isp/${assinaturaID}` +
          `/mensalidades/${this.modalCobrancas.mensalidade.id}/cobrancas`;

      if (!assinaturaID) {
        this.$router.push({ path: `/assinaturas-isp` });
        return;
      }

      loading(true);

      api.get(apiUrl).then(res => {
        this.modalCobrancas.cobrancas = res.data.items;
        this.modalCobrancas.paginador.pagina = res.data.page;
        this.modalCobrancas.paginador.totalItens = res.data.totalItems;

        this.modalCobrancasAbre();
        loading(false);
      }).catch(error => {
        loading(false);

        dialogo.erroHttp(error).then(() => {
          //this.modalCobrancasFecha();
        });
      });
    },

    modalCobrancasAbreHandle(mensalidade) {
      this.modalCobrancas.mensalidade = clone(mensalidade);

      this.modalCobrancasReset();
      this.modalCobrancasLista();
    },

    viewModalReceiveBoletoHandle(cobranca) {
      this.modalCobrancasFecha();
      this.modalReceiptBoletoAbreHandle(cobranca);
    },

    modalCobrancasRemoverHandle(boleto) {
      dialogo.confirmacao(`Confirma cancelar este boleto?`).then(() => {
        loading(true);

        api.delete(`/boletos/${boleto.id}`).then(() => {
          loading(false);
          boleto.status = 'canceled';
          // this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },
    // modal cobranças
    /////////////////////////////////////


    /////////////////////////////////////
    // modal novo recebimento mensalidade
    modalReceiptOrderReset() {
      this.modalReceiptOrder.data.walletId = undefined;
      this.modalReceiptOrder.data.paymentDate = shared.date.todayMysql();
      this.modalReceiptOrder.data.interest = 0;
      this.modalReceiptOrder.data.discount = 0;
    },

    modalReceiptOrderAbre() {
      document.getElementById('btn-modal-recebe-mensalidade-abre').click();
    },

    modalReceiptOrderFecha() {
      document.getElementById('btn-modal-recebe-mensalidade-fecha').click();
    },

    modalReceiptOrderAbreHandle(order) {
      this.modalReceiptOrder.order = order;
      this.modalReceiptOrderReset();
      this.modalCobrancasFecha();
      this.modalReceiptOrderAbre();
    },

    modalReceiptOrderFechaHandle() {
      this.modalCobrancasAbre();
    },

    modalReceiptOrderPodeCriarCobranca() {
      const data = this.modalReceiptOrder.data;

      switch (true) {
        case (!data.walletId):
        case (!data.paymentDate):
        case (data.interest < 0):
        case (data.discount < 0):
          return false;

        default:
          return true;
      }
    },

    modalReceiptOrderReceberHandle() {
      const data = this.modalReceiptOrder.data;
      const apiUrl = `/orders/${this.modalReceiptOrder.order.id}/receipts`;

      const sendData = {
        walletId: data.walletId,
        paid: this.modalReceiptOrderTotal,
        discount: data.discount,
        interest: data.interest,
        paymentDate: godate.fromMysql(data.paymentDate)
    }

      loading(true);
      api.post(apiUrl, sendData).then(res => {
        loading(false);
        this.modalReceiptOrder.order.status = res.data.status;
        this.modalReceiptOrder.order.transactions = res.data.transactions;
        this.modalReceiptOrderFecha();
        document.getElementById('refresh-monthly-list').click();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });

    },



    /*modalReceiptOrderEditarHandle() {
      dialogo.confirmacao(`Confirma efetuar o recebimento desta cobrança?`).then(() => {
        const apiUrl = ``;

        const sendData = {
          cobranca_id: 0,
        };

        loading(true);

        api.post(apiUrl, sendData).then(() => {
          loading(false);
          this.modalReceiptOrderFecha();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },*/
    // modal novo recebimento mensalidade
    /////////////////////////////////////


    /////////////////////////////////////
    // modal edita cobranca
    newOrderModalOpenHandle(monthly) {
      this.newOrder.monthly = monthly;

      this.closeModalViewMonthly();
      this.newOrderModalReset();
      this.newOrderModalOpen();
    },

    newOrderModalReset() {
      this.newOrder.data.method = undefined;
      this.newOrder.data.dueDate = date.todayMysql();
    },

    newOrderModalOpen() {
      document.getElementById('btn-modal-nova-cobranca-mensalidade-abre').click();
    },

    newOrderModalFecha() {
      document.getElementById('btn-modal-nova-cobranca-mensalidade-fecha').click();
    },

    newOrderModalFechaHandle() {
      this.newOrderModalFecha();
      this.openModalViewMonthly();
    },

    newOrderModalPodeCriarCobranca() {
      const dueDate = date.fromMysql(this.newOrder.data.dueDate);
      const today = date.fromMysql(date.todayMysql());

      switch (true) {
        case (dueDate < today):
        case (!this.newOrder.data.method):
          return false;

        default:
          return true;
      }
    },

    newOrderModalCreateHandle() {
      const apiUrl = `/isp/subscriptions/${this.assinaturaID}/monthlies/${this.newOrder.monthly.id}/order`;
      const sendData = clone(this.newOrder.data);
      sendData.customerId = this.assinatura.customerId;
      sendData.dueDate = godate.fromMysql(sendData.dueDate);

      loading(true);
      api.post(apiUrl, sendData).then(res => {
        loading(false);
        this.newOrder.monthly.orderId = res.data.id;
        this.newOrderModalFecha();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },



    /*newOrderModalEditarHandle() {
      dialogo.confirmacao(`Confirma efetuar o recebimento desta cobrança?`).then(() => {
        const apiUrl = ``;

        const sendData = {
          cobranca_id: 0,
        };

        loading(true);

        api.post(apiUrl, sendData).then(() => {
          loading(false);
          this.newOrderModalFecha();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },*/
    // modal edita cobranca
    /////////////////////////////////////

    /////////////////
    // new boleto modal
    showNewBillModalHandle(order) {
      this.newBoleto.order = order;
      this.newBoleto.form.method = undefined;
      this.newBoleto.form.dueDate = date.todayMysql();
      this.newBoleto.form.amount = this.formataMoeda(this.orderPendingAmount(order));
      this.newBoleto.form.bankId = undefined;

      if (!this.newBoleto.order.boletos) {
        this.newBoleto.order.boletos = [];
      }

      this.viewOrderModalClose();
      this.showNewBillModal();
    },

    closeNewBillModalHandle() {
      this.viewOrderModalShow();
      this.closeNewBillModal();
    },

    showNewBillModal() {
      document.getElementById('btn-modal-new-boleto-order-abre').click();
    },

    closeNewBillModal() {
      document.getElementById('btn-modal-new-boleto-order-fecha').click();
    },

    newBoletoCanCreate() {
      const dueDate = date.fromMysql(this.newBoleto.form.dueDate);
      const today = date.fromMysql(date.todayMysql());
      const amount = this.moedaToInt(this.newBoleto.form.amount);

      switch (true) {
        case (!this.newBoleto.form.bankId):
        case (dueDate < today):
        case (!amount || amount < 0 || amount > this.orderPendingAmount(this.newBoleto.order)):
          return false;

        default:
          return true;
      }
    },

    newBoletoCreateHandle() {
      const sendData = {
        bankId: this.newBoleto.form.bankId,
        dueDate: godate.fromMysql(this.newBoleto.form.dueDate),
        orderId: this.newBoleto.order.id,
        customerId: this.newBoleto.order.customerId,
        addressId: this.assinatura.addressId,
        amount: this.moedaToInt(this.newBoleto.form.amount),
      };

      loading(true);
      api.post(`/boletos`, sendData).then(res => {
        loading(false);
        this.newBoleto.order.boletos.push(res.data);
        this.closeNewBillModalHandle();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    // @click="newBoletoCreateHandle()"
    // @click="closeNewBillModalHandle()"


    // new boleto modal
    /////////////////

    /////////////////////////////////////
    // modal recebe cobrança
    modalReceiptBoletoReset() {
      this.modalReceiptBoleto.walletId = undefined;
      this.modalReceiptBoleto.boleto = undefined;
      this.modalReceiptBoleto.form.paymentDate = date.todayMysql();
      this.modalReceiptBoleto.form.interest = 0;
      this.modalReceiptBoleto.form.discount = 0;
    },

    modalReceiptBoletoAbre() {
      document.getElementById('btn-modal-receipt-boleto-abre').click();
    },

    modalReceiptBoletoFecha() {
      document.getElementById('btn-modal-receipt-boleto-fecha').click();
    },

    modalReceiptBoletoAbreHandle(cobranca) {
      this.modalReceiptBoletoReset();

      this.modalReceiptBoleto.boleto = cobranca;

      this.modalReceiptBoletoAbre();
    },

    modalCobrancasFechaHandle() {
      this.modalCobrancasAbre();
    },

    modalReceiptBoletoCanReceive() {
      switch (true) {
        case (!this.modalReceiptBoleto.form.paymentDate):
        case (!this.modalReceiptBoleto.form.walletId):
          return false;

        default:
          return true;
      }
    },

    modalReceiptBoletoReceberHandle() {
      dialogo.confirmacao(`Confirma efetuar o recebimento desta cobrança?`).then(() => {
        const data = this.modalReceiptBoleto.form;
        const apiUrl = `/boletos/${this.modalReceiptBoleto.boleto.id}/receipts`;

        const sendData = {
          walletId: data.walletId,
          paid: this.modalReceiptBoletoTotal,
          discount: data.discount,
          interest: data.interest,
          paymentDate: godate.fromMysql(data.paymentDate)
        };

        loading(true);
        api.post(apiUrl, sendData).then(res => {
          loading(false);
          this.viewOrder.order.status = res.data.status;
          this.viewOrder.order.transactions = res.data.transactions;
          this.modalReceiptBoletoFecha();
          this.viewOrderModalHandle(this.viewMonthly.monthly.id)
          document.getElementById('refresh-monthly-list').click();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },
    // modal recebe cobrança
    /////////////////////////////////////


    formataMesReferencia(entrada, abreviado) {
      const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
      const mesesAbr = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
      var data = new Date();

      if (typeof entrada === "object") {
        data = entrada;
      } else {
        data = new Date(entrada);
      }

      if (abreviado) {
        return mesesAbr[data.getUTCMonth()] +'/'+ data.getUTCFullYear();
      }

      return meses[data.getUTCMonth()] +'/'+ data.getUTCFullYear();
    },

    //TODO: fazer esta função ser global
    insereChar(texto, char, posicao) {
        return texto.slice(0, posicao) + char + texto.slice(posicao);
    },

    padLeft(variavel, qtdChars, str){
        variavel = String(variavel);
        if (variavel.length >= qtdChars) {
            return variavel;
        }
        return Array(qtdChars - variavel.length + 1).join(str||'0') + variavel;
    },

    formataMoeda(valorInt) {
        if (!valorInt) {
            valorInt = 0;
        }
        let negativo = false;
        if (valorInt < 0) {
            negativo = true;
            valorInt = valorInt * (-1);
        }

        let vOut = this.padLeft(valorInt, 3) || '000';
        vOut = this.insereChar(vOut, ',', vOut.length - 2);
        let charLen = 6;
        let textLen = vOut.length;

        while (textLen > charLen) {
            vOut = this.insereChar(vOut, '.', textLen - charLen);
            charLen += 4;
            textLen = vOut.length;
        }

        if (negativo === true) {
            vOut = '-' + vOut;
        }

        return vOut;
    },

    moedaToInt(valor) {
        valor = String(valor);

        if (!valor) {
            valor = '0,00';
        }

        var valorInt = +valor.replace(/[^0-9]/g, '');

        if (typeof valorInt === 'number') {
            return valorInt;
        }

        return 0;
    },
  },
  computed: {
    shared() {
      return shared;
    },
    modalReceiptOrderTotal() {
      const amount = this.modalReceiptOrder.order.amount;
      const interest = this.moedaToInt(this.modalReceiptOrder.data.interest);
      const discount = this.moedaToInt(this.modalReceiptOrder.data.discount);
      const total = amount + interest - discount;

      return total;
    },
    newOrderModalTotal() {
      const amount = this.modalCobrancas.mensalidade.amount;
      const interest = this.moedaToInt(this.newOrder.data.interest);
      const discount = this.moedaToInt(this.newOrder.data.discount);
      const total = amount + interest - discount;

      return total;
    },
    modalReceiptBoletoTotal() {
      const amount = this.modalReceiptBoleto.boleto.amount;
      const interest = this.moedaToInt(this.modalReceiptBoleto.form.interest);
      const discount = this.moedaToInt(this.modalReceiptBoleto.form.discount);
      const total = amount + interest - discount;

      return total;
    },

    hasChecked() {
      return this.mensalidades.some(x => x._checked === true);
    },

    printCarnetUrl() {
      const boletoStatus = ["waiting", "received"];
      const monthlyIds = this.mensalidades.filter(x => x._checked).map(x => x.id);
      const ids = this.boletos.filter(x => (monthlyIds.includes(x.ispMonthlyId) && boletoStatus.includes(x.status))).map(x => x.id);

      return `${baseurl}/boletos/print-carnet?id[in]=${ids}`;
    },
  },
  created() {
    const assinaturaID = this.$route.params.assinaturaID;

    if (!assinaturaID) {
      this.$router.push({ path: `/assinaturas-isp` });
      return;
    }
    this.assinaturaID = assinaturaID;

    this.carregaAssinatura();
    this.loadBanks();
    this.loadWallets();
  }
}


</script>
<style>
</style>